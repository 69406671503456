import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import PrivacyHeader from "./components/PrivacyHeader";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { unflatten } from "flat";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

const unflattenTemplateItems = (templateItems) => {
  let content = {};
  content = templateItems.reduce(
    (agg, curr) => ({
      ...agg,
      [curr.key]: {
        ...curr,
        value: curr.value?.map((item) => item.content),
      },
    }),
    {}
  );

  content = unflatten(content);
  return content.terms;
};

export default class Terms extends Component {
  state = {
    ans: null,
    heading: "",
    terms: [],
  };
  getTerms = () => {
    axios
      .get(`${process.env.GATSBY_STRAPI_BASE_URL}/api/junio-tandcs?populate=content.value`)
      .then((res) => {
        const termData =  res.data?.data.find(item => item?.attributes.type == "TERM")
        this.setState({
          heading: termData.attributes.heading,
          terms: unflattenTemplateItems(termData.attributes.content),
        });
      }).catch(() => {});
  };
  componentDidMount() {
    this.getTerms();
  }
  render() {
    return (
      <React.Fragment>
        <PrivacyHeader />
        <div className="pnt">
          <h2>{this.state.heading}</h2>
          <h4></h4>
          <div className="col col-12">
            {this.state.terms?.map((item, index) => {
              return (
                <div className="acc-cont" key={index}>
                  <div
                    className="c8-p1 d-flex justify-content-between align-items-center"
                    onClick={() => {
                      this.setState({
                        ans: this.state.ans == index ? null : index,
                      });
                    }}
                  >
                    {item.heading}
                    {this.state.ans === index ? (
                      <FontAwesomeIcon icon={faChevronUp} />
                    ) : (
                      <FontAwesomeIcon icon={faChevronDown} />
                    )}
                  </div>
                  <div
                    className="c8-d1 text-left"
                    style={{
                      display: this.state.ans === index ? "block" : "none",
                    }}
                  >
                    {item.value.length > 0 && (
                      <ReactMarkdown className="markdown-custom">{item.value[0]}</ReactMarkdown>
                    )}
                    {item.link && (
                      <div
                        className="c8-d1 text-left"
                        style={{
                          display: this.state.ans === index ? "flex" : "none",
                          justifyContent: "center",
                        }}
                      >
                        <iframe
                          style={{ width: 800, height: 800 }}
                          src={item.link}
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </React.Fragment>
    );
  }
}